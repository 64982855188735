import React, {useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { driverSlice } from "./../../store";
import { employeeSlice } from "../../store/employees";
import { AuthService, EmployeeService } from "../../services";

const EmployeeList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employees, setEmployees] = useState([]);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (!AuthService.canViewEmployees()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    EmployeeService.getAllEmployees().then((data) =>
      setEmployees(data.data)
    );
  }, []);

  const redirectToAdmin = () => {
    navigate(`/admin/customer-report`)
  }

  const goToEdit = (id) => {
    navigate(`/employees/edit/${id}`)
  }


  const goToView = (id) => {
    navigate(`/employees/${id}`)
  }

  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>All Employees <button className="btn btn-link btn-sm" onClick={() => {redirectToAdmin()}}>Back</button></h5> 
        </div>
      </div>
      <div className="list row mb-4">
        <div className="col-md-12">
          <div className="mb-4">Filter By Name: <input type="text" value={keyword} onChange={(e) => setKeyword(e.currentTarget.value)}/></div>
          <table className="personnel-info-table"> 
            <thead>
              <tr>
                <th>Name</th>
                <th>Chinese Name</th>
                <th>Username</th>
                <th>Status</th>
                <th>Roles</th>
                <th></th>
              </tr>
              
            </thead>
            <tbody>
              {
                employees && employees.filter(item => item.status === 'active').filter((item)=> item?.name.toLowerCase().includes(keyword.toLowerCase())).map(employee => <tr key={employee.id}>
                  <td>{employee?.name}</td>
                  <td>{employee?.name_cn}</td>
                  <td>{employee?.username}</td>
                  <td>{employee?.status}</td>
                  <td>{employee?.roles?.join(', ')}</td>
                  <td>
                    {AuthService.canAddOrEditEmployees() && <button className="btn btn-primary btn-sm me-2" onClick={() => goToEdit(employee?.id)}>Edit</button> }
                    {AuthService.canViewEmployees() && <button className="btn btn-default btn-sm me-2" onClick={() => goToView(employee?.id)}>View</button>}
                  </td>
                </tr>)
              }
            </tbody>
          </table>
           
        </div>
      </div>
    </>
  )
};

export default EmployeeList;